import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect } from "react";
import React, { useContext, useState } from "react";
import { AppContext } from "../context/AppProvider";
export const ChainGuard = () => {
  const {
    setCorrectChain,

    correctChain,
  } = useContext(AppContext);

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!chain) {
                setCorrectChain(false);
                return <></>;
              }
              if (chain.unsupported) {
                setCorrectChain(false);
              } else {
                setCorrectChain(true);
              }
              return <></>;
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
