import { atom } from "jotai";

export const isModalOpenAtom = atom(false);
export const isGameModalOpenAtom = atom(false);
export const isBuyModalOpenAtom = atom(false);
export const isClaimModalOpenAtom = atom(false);
export const isTransferModalOpenAtom = atom(false);
export const isHistoryModalOpenAtom = atom(false);
export const isGuideModalOpenAtom = atom(false);
export const isMigrateModalOpenAtom = atom(false);
