import { useAtom } from "jotai";
import { isBuyModalOpenAtom } from "../store/modal";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
//import { faMinus, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import OpenLogin from "@toruslabs/openlogin";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { getUserPrincipal } from "../service/icdragoncanister";
import { icpAgent } from "../service/icpledgercanister";
import { Principal } from "@dfinity/principal";
import { AccountIdentifier } from "@dfinity/ledger-icp";
import { parseEther } from "viem";
import { useAccount } from "wagmi";
import {
  useSendTransaction,
  usePrepareTransactionRequest,
  useWaitForTransactionReceipt,
} from "wagmi";
import Icon, {
  PoweroffOutlined,
  CloseOutlined,
  GoogleOutlined,
} from "@ant-design/icons";

import { actorCreation } from "../service/icdragoncanister";

import { AppContext } from "../context/AppProvider";

import { Button, Modal, message } from "antd";
import { getBalance } from "@wagmi/core";
import { config } from "../evm/config";

export default function BuyTicketModal() {
  const [ethformatted, setEthformatted] = useState(0);
  const [ethBalance, setEthbalance] = useState(0);
  const [isBuyModalOpen, setModalOpen] = useAtom(isBuyModalOpenAtom);
  const { t } = useTranslation();
  const [buying, setBuying] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const {
    loginInstance,
    canisterActor,
    setUserData,
    userData,
    ticketPrice,
    gameData,
    icpAgent,
    walletAddress,
    ethVault,
    setEthconnected,
    ethconnected,
    correctChain,
    setToggleMobileMenu,
  } = useContext(AppContext);

  const [ticketQuantity, setticketQuantity] = useState(10);
  const [accountId, setAccountid] = useState("");
  useEffect(() => {
    const getethbalance = async () => {
      var balance = 0;
      try {
        balance = await getBalance(config, {
          address: acc_.address,
        });
      } catch (er) {}

      setEthbalance(Number(balance.value));
      setEthformatted(Number(balance.formatted).toFixed(5));
    };
    if (walletAddress) {
      var acc = {
        principal: Principal.fromText(walletAddress),
        subaccount: [],
      };
      var accid = AccountIdentifier.fromPrincipal(acc);
      //console.log(accid.toHex(), "<<<<<<<<<< Acc Id");
      setAccountid(accid.toHex());
    }
    if (ethconnected) {
      getethbalance();
    }
  }, [walletAddress, ethconnected]);
  useEffect(() => {}, [loginInstance]);
  const handleMinusClick = () => {
    const newValue = Math.max(ticketQuantity - 1, 1); // Ensure the value is at least 100
    setticketQuantity(newValue);
    //dispatch(changeInvestment(newValue));
  };
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    setConnected(ethconnected);
    const getethbalance = async () => {
      var balance = 0;
      try {
        balance = await getBalance(config, {
          address: acc_.address,
        });
      } catch (er) {}

      setEthbalance(Number(balance.value));
      setEthformatted(Number(balance.formatted).toFixed(5));
    };
    if (ethconnected) {
      getethbalance();
    }
  }, [loginInstance, userData, gameData, icpAgent, ethconnected, correctChain]);
  const handlePlusClick = () => {
    var newValue = ticketQuantity + 1;

    //dispatch(changeInvestment(newValue));
    setticketQuantity(newValue);
  };

  const handleInputChange = (event) => {
    const newValue = Math.max(Number(event.target.value), 1); // Ensure the value is at least 100
    //dispatch(changeInvestment(newValue));
    setticketQuantity(newValue);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const acc_ = useAccount();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    if (!loading) {
      setShowModal(false);
    }
  };
  const ethtogwei = 1000000000000000000n;
  const getUserBalance = async () => {
    setUserBalance(0);
    return 0;
  };
  const [bookId, setBookId] = useState(0);
  const {
    data: hash,
    isLoading,
    isPending,
    isSuccess,
    sendTransaction,
  } = useSendTransaction();

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      confirmations: 3,
      hash: hash,
    });

  useEffect(() => {
    //useeffect buy dragon after transaction not pending
    if (buying) {
      var dragonAgent_ = canisterActor;

      const buy_dragon_ticket = async () => {
        var buy_ticket = await dragonAgent_.buy_ticket(
          ticketQuantity,
          hash,
          bookId
        );
        console.log(buy_ticket);
        //hash = undefined;
        var user_ = await dragonAgent_.getUserData();
        setUserData(user_);
        setBuying(false);
        setShowModal(false);
      };
      //useEffect to wait eth transaction

      if (hash !== undefined && !isPending && isConfirmed) {
        //console.log("buying " + bookId, "<<<<<now buying");
        buy_dragon_ticket();
      } else if (hash == undefined && !isPending && !isConfirmed) {
        setBuying(false);
      }
    }
  }, [hash, isPending, isConfirmed]);

  const handlebuy = async () => {
    setBuying(true);

    var totalPrice_ = (ticketQuantity * Number(ticketPrice)) / 10 ** 18;
    var totalPrice = totalPrice_.toFixed(5).toString();
    var book_ticket = await canisterActor.book_ticket(ticketQuantity);
    setBookId(Number(book_ticket));

    try {
      const result = sendTransaction({
        to: ethVault,
        value: parseEther(totalPrice),
      });
    } catch (err) {
      console.log(err);
    }
  };

  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    const frontChars = Math.ceil(num / 2);
    const backChars = Math.floor(num / 2);
    return (
      str.substr(0, frontChars) + "..." + str.substr(str.length - backChars)
    );
  }

  function copyToClipboard() {
    //const textToCopy = walletAddress; // Replace with your actual string variable
    const textToCopy = acc_.address;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Wallet address copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  return (
    <Transition appear show={isBuyModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 w-full" onClose={closeModal}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/35 w-screen" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto w-screen">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Main modal */}
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-warm-white p-6 text-left align-middle shadow-xl transition-all relative">
                <Dialog.Title
                  as="h3"
                  className="text-xl grid  lg:text-2xl font-passion leading-6 text-[#1E3557] justify-center items-start text-center"
                >
                  Buy Ticket
                  <span class="lg:text-base text-sm w-full">
                    You have{" "}
                    {ethconnected && userData
                      ? Number(userData.availableDiceRoll)
                      : 0}{" "}
                    {Number(userData.availableDiceRoll) > 1
                      ? "tickets"
                      : "ticket"}
                  </span>
                </Dialog.Title>
                <div className="mt-2">
                  <div className="text-sm text-gray-500">
                    <div className="w-full flex flex-col  bg-dashboard-blue p-4 rounded-2xl  ">
                      <div className="flex flex-col items-stretch w-full p-0 ">
                        <div className="w-full p-2 py-0 flex justify-center items-center lexend-light ">
                          <button
                            onClick={handleMinusClick}
                            className=" rounded-md px-2 py-0 m-4 text-[#9B3535] text-base hover:text-[#cff0ea]"
                          >
                            <FontAwesomeIcon icon={faMinusCircle} size="2xl" />
                          </button>
                          <div className="lg:text-3xl text-2xl  text-[#1E3557] lg:min-w-[200px] min-w-[100px] text-center hero-lexend">
                            <input
                              className="lg:w-[200px] w-[100px] number-input-container bg-transparent lg:text-3xl text-2xl  text-[#1E3557] text-center  "
                              type="number"
                              value={ticketQuantity}
                              onChange={handleInputChange}
                              min={1}
                              step={1}
                            />
                          </div>
                          <button
                            onClick={handlePlusClick}
                            className=" rounded-md px-2 py-0 m-4 text-[#9B3535] text-base hover:text-[#cff0ea]"
                          >
                            <FontAwesomeIcon icon={faPlusCircle} size="2xl" />
                          </button>
                        </div>
                      </div>
                      <div className="text-base text-center  lg:text-[20px] px-6 py-3 leading-none ">
                        {Number(ticketPrice) / 10 ** 18} ETH per ticket
                      </div>
                      <div className="text-2xl text-center font-passion text-[#1E3557]  lg:text-[30px] px-6 py-3 leading-none ">
                        Total :{" "}
                        {(Number(ticketPrice) / 10 ** 18) * ticketQuantity} ETH
                      </div>
                      {buying ? (
                        <button className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]">
                          {"Purchase in Progress.."}
                        </button>
                      ) : Number(ticketPrice) * ticketQuantity < ethBalance ? (
                        <button
                          onClick={() => handlebuy()}
                          className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]"
                        >
                          {"Buy Ticket"}
                        </button>
                      ) : (
                        <button className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]">
                          {"Insufficient Fund"}
                        </button>
                      )}
                    </div>
                    <div className="text-center w-full text-dark-blue">
                      your balance : {Number(ethformatted)} ETH
                    </div>
                    <div className="text-sm lg:text-base text-gray-500">
                      <div className="w-full grid  bg-dashboard-blue p-4 border border-[#1E3557] rounded-2xl my-5 ">
                        <div className="flex flex-col items-center justify-center text-center w-full p-0 ">
                          <div className="lg:text-lg text-base text-dark-blue font-passion">
                            {" "}
                            Deposit ETH to your wallet :{" "}
                          </div>
                          <div
                            onClick={() => {
                              copyToClipboard();
                            }}
                            className="text-lg lg:text-xl text-bright-red font-passion cursor-pointer"
                          >
                            {" "}
                            {truncateString(acc_.address, 12)}📋
                          </div>

                          <div className="lg:text-lg grid items-center justify-center text-base p-3 text-[#1E3557] w-full text-center hero-lexend">
                            <QRCode
                              value={acc_.address}
                              size="64"
                              level="L"
                              bgColor="white"
                              fgColor="black"
                            />
                          </div>
                          <div className="text-xs lg:text-sm">
                            {" "}
                            (Scan to copy your wallet address)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4"></div>

                {/* Close button */}
                <button
                  type="button"
                  className="absolute top-6 right-6 rounded-full"
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-x"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
