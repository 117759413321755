import AppProvider from "./context/AppProvider";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
//import { QueryClient, QueryClientProvider } from "react-query";

import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

import Layout from "./layout";
import Landing from "./containers/Landing";
import Migrate from "./containers/Migrate";
import MigrateICP from "./containers/MigrateICP";

import '@rainbow-me/rainbowkit/styles.css';



import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  arbitrum,
} from 'wagmi/chains';
const queryClient = new QueryClient();

const config = getDefaultConfig({
  appName: 'Dragon Eyes',
  projectId: 'ac82f132063028b81bff73ea105122c7',
  chains: [arbitrum],
  ssr: true, // If your dApp uses server side rendering (SSR)
});

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      
    ],
  },
]);

const App = () => {
  return (
   
      <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
      <AppProvider>
        <RouterProvider router={router} />
        </AppProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
      </WagmiProvider>
  
  );
};

export default App;
