import React from "react";

import { useSetAtom } from "jotai";
import { CustomConnect } from "../../components/CustomConnect";
import { CustomConnectSmall } from "../../components/CustomConnectSmall";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Row, Col, Typography, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import LokaBackground from "../../assets/image/loka_bg.png";
import Header from "../../components/Header";
import GameModal from "../../components/GameModal";
import HistoryModal from "../../components/HistoryModal";
import moneyBag from "../../assets/drgn_base.jpg";
import moneyBag2 from "../../assets/dragon_mobile.png";
import dragonMobile from "../../assets/drgn_base_mobile.jpg";
import { Principal } from "@dfinity/principal";
import { AccountIdentifier } from "@dfinity/ledger-icp";
import bottom_chest from "../../assets/bottom_chest.png";
import dice from "../../assets/dice.png";
import loka_icon from "../../assets/dragon_icon_.png";
import icp_logo from "../../assets/small_icp.png";
import ConnectModal from "../../components/ConnectModal";
import { AppContext } from "../../context/AppProvider";
import confetti from "canvas-confetti";
import "./style.css";
import { useEffect, useState, useContext } from "react";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import {
  isGameModalOpenAtom,
  isModalOpenAtom,
  isBuyModalOpenAtom,
  isGuideModalOpenAtom,
} from "../../store/modal";
import {
  actorCreation,
  getUserPrincipal,
} from "../../service/icdragoncanister";
import BuyTicketModal from "../../components/BuyTicketModal";
import ClaimModal from "../../components/ClaimModal";
import TransferModal from "../../components/TransferModal";
import GuideModal from "../../components/GuideModal";
const { Title } = Typography;

const Landing = () => {
  const setModalOpen = useSetAtom(isGameModalOpenAtom);
  const setConnectOpen = useSetAtom(isModalOpenAtom);
  const setBuyOpen = useSetAtom(isBuyModalOpenAtom);
  const setGuideOpen = useSetAtom(isGuideModalOpenAtom);
  const secret = "e10ed9dc1d40db7943f643";
  //const secret = process.env.REACT_DRAGON_K;
  const generalPrivKey =
    "0bc9866cbc181a4f5291476f7be00ca4f11cae6787e10ed9dc1d40db7943f643";
  //0x36e545ab5687e5d80e67e787b525c05921f4f86f;

  // const generalPrivKey =
  //"0xb4ecBBE389B70499fdaA64BB35932f07fB650912e10ed9dc1d40db7943f643";
  const [num, setNum] = useState(250);
  const {
    loginInstance,
    canisterActor,
    setCanisterActor,
    gameData,
    userData,
    ticketPrice,
    setGameData,
    setTicketPrice,
    walletAddress,
    walletAlias,
    ethconnected,
    correctChain,
    setEthVault,
  } = useContext(AppContext);
  const [connected, setConnected] = useState(false);
  const [halving, setHalving] = useState(false);
  /**
   * for demo purposes to show dynamic stash text
   * You can delete this
   */
  const wta = (str) => {
    if (str != "none") {
      var acc = {
        principal: Principal.fromText(str),
        subaccount: [],
      };
      var accid = AccountIdentifier.fromPrincipal(acc);
      accid = accid.toHex();
      //console.log(accid, "<<<<<<<<<<<<<<<<<< accid");
      return accid;
    }
    return "";
  };
  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    const frontChars = Math.ceil(num / 2);
    const backChars = Math.floor(num / 2);
    return (
      str.substr(0, frontChars) + "..." + str.substr(str.length - backChars)
    );
  }
  const getUserDatas = async () => {
    //const actor = actorCreation(loginInstance.privKey);
    //const actor = actorCreation(generalPrivKey);
  };

  const getGameDatas = async () => {
    const actor = actorCreation(generalPrivKey);
    let game_ = await actor.getCurrentGame();
    var principalString_ = getUserPrincipal(generalPrivKey).toString();
    //console.log(principalString_, "<<<<< principal key using metamask");
    //console.log("calling dragon function to get ticket price");
    let ticket_ = await actor.getTicketPrice();
    // console.log(ticket_, "<<<<<<<< ticket price");
    let halving_ = await actor.getNextHalving();
    setHalving(Number(halving_));
    setTicketPrice(ticket_);
    setGameData(game_);
    setEthVault(game_.ok.houseVault);
    console.log(game_.ok.houseVault, "<<<<<<<house");
  };
  useEffect(() => {
    //console.log(gameData);
    const actor_ = actorCreation(generalPrivKey);
    gameData ? setNum(Number(gameData.ok.reward) / 10 ** 18) : setNum(0);
    loginInstance && loginInstance.privKey && userData
      ? setConnected(true)
      : setConnected(false);
  }, [loginInstance, userData, gameData, generalPrivKey]);
  useEffect(() => {
    getGameDatas();
  }, [generalPrivKey]);

  useEffect(() => {
    if (connected) {
      getUserDatas();
    }
  }, [connected]);

  const launchConfetti = () => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count >= 5) {
        clearInterval(intervalId);
        return;
      }

      confetti({
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
      });

      count++;
    }, 500);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const targetTimestamp = "Jan 1, 2025 00:00:00";
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const difference = halving - now;
      if (halving) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
      }

      if (difference < 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [halving]);
  if (!correctChain) {
    return (
      <div>
        <Header className="z-999999" />
        {isDesktopOrLaptop ? (
          <section>
            <div className="w-full flex flex-col items-center ">
              {/* Hero */}
              <div className="flex flex-col w-full  relative items-center px-5 lg:px-5 gap-10 lg:gap-0">
                {/* Money bag */}
                <div className="w-full lg:absolute  relative z-10">
                  <div className="relative flex items-center justify-center ">
                    <img
                      src={moneyBag}
                      className="h-screen w-screen  scale-125 lg:scale-100 hidden lg:flex"
                    />
                    <img
                      src={moneyBag}
                      className="w-full h-full object-cover scale-100 lg:scale-100  lg:hidden"
                    />
                  </div>
                  {/* Roll em now */}
                </div>

                <div className="z-40 relative bg-dark-cream bg-opacity-65 py-2 lg:ml-auto top-5 lg:top-0 mt-32   rounded-lg w-full lg:w-[350px]  px-8 flex flex-col gap-3">
                  {/* Dice */}
                  <div className="absolute w-64 -top-20 right-0 z-0">
                    <img src={dice} />
                  </div>

                  <div className="h-14"></div>
                  {/* Hook text */}

                  <p className="text-[28px] text-dark-blue font-passero lg:text-right leading-tight">
                    wrong network.
                  </p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div
            className="h-screen  bg-cover bg-center"
            style={{ backgroundImage: `url(${moneyBag})` }}
          >
            <div className="z-40 relative bg-dark-cream bg-opacity-65 py-2 lg:ml-auto top-5 lg:top-0    rounded-lg w-full lg:w-[350px]  px-8 flex flex-col gap-3">
              {/* Dice */}
              <div className="absolute w-64 right-0 z-0">
                <img src={dice} />
              </div>

              <div className="h-14"></div>
              {/* Hook text */}

              <p className="text-[28px] text-dark-blue font-passero lg:text-right leading-tight pt-20">
                <CustomConnectSmall />
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="landing-container h-screen">
      {isDesktopOrLaptop ? (
        <div>
          <div className="grid w-full">
            <div className="hidden lg:grid text-3xl font-passion text-center bg-opacity-70 items-center justify-center fixed top-[11%] left-[5%] z-40 ">
              <div className="hidden lg:grid text-3xl font-passion text-center bg-opacity-70 items-center justify-center  z-40 text-red-500 w-200 bg-warm-white rounded-2xl p-4 px-6">
                <div className="flex p-0">
                  {halving
                    ? Object.entries(timeLeft).map(([unit, value]) => (
                        <div key={unit} className="px-4 grid">
                          <div className="text-xl font-bold">
                            {value < 10 ? `0${value}` : value}
                          </div>
                          <div className="text-lg uppercase">{unit}</div>
                        </div>
                      ))
                    : "Starting Countdown..."}
                </div>
                <div className="text-xl w-full text-center justify-center items-center text-dark-blue">
                  {" "}
                  until next $EYES halving
                </div>
              </div>
              <div className="p-2 flex items-center justify-center text-left text-3xl w-full text-warm-white rounded-2xl mt-2 ">
                {" "}
                Reward Distributed :{" "}
                <span className="text-[#B63B40] text-5xl px-2">
                  {gameData ? (
                    parseFloat(Number(gameData.ok.totalReward) / 10 ** 18)
                      .toFixed(5)
                      .toLocaleString()
                  ) : (
                    <></>
                  )}{" "}
                  ETH
                </span>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex font-passion fixed bottom-[0%] lg:bottom-[0%] z-40 w-full">
            <div className="flex flex-col items-center relative">
              <div className="relative">
                <img
                  src={bottom_chest}
                  alt="Random"
                  className="w-screen h-full z-50 object-cover"
                />
                {num !== null && (
                  <span className="absolute bottom-[22%] left-[43%] text-4xl text-red-500 font-bold">
                    <div className="grid text-center items-center justify-center">
                      <p className="text-sm lg:text-2xl text-dark-red current-stash-transform leading-none  lg:mb-1">
                        {!gameData ? "Connect Wallet" : "Dragon's Chest"}
                      </p>
                      <p className="text-xl lg:text-7xl text-gray-300 leading-none ">
                        {!gameData ? "" : num}
                      </p>
                    </div>
                  </span>
                )}
                <div className="absolute bottom-[12%] left-[5%] text-4xl text-red-500 font-bold bg-[#C4BCC8] rounded-3xl p-4">
                  <div className="grid text-center items-center justify-center">
                    <p className="text-sm  lg:text-2xl text-bright-red  leading-none mix-blend-multiply lg:mb-1 ">
                      {!gameData ? "Connect Wallet" : "Dwarf's Bonus"}
                    </p>
                    <p className="text-xl lg:text-5xl text-dark-blue leading-none  ">
                      {!gameData
                        ? ""
                        : (Number(gameData.ok.bonus) / 10 ** 18).toString()}
                    </p>
                    <p className="text-xl lg:text-xl text-dark-blue leading-none  ">
                      {!gameData ? (
                        ""
                      ) : (
                        <>
                          {"   "}
                          &nbsp;&nbsp;{"Current Highest Roll "}
                          <br />
                          <span className="text-xl lg:text-3xl text-red-500">
                            {Number(gameData.ok.highestDice).toString()}
                          </span>
                          <br />
                          {truncateString(
                            gameData.ok.highestRoller.toString(),
                            14
                          )}
                          <br />
                          {walletAlias == gameData.ok.highestRoller.toString()
                            ? "(You!)"
                            : ""}
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="absolute grid bottom-[2%] left-[45%] text-xl text-warm-white   p-4">
                  <div className="grid text-center items-center justify-center">
                    Dragon Eyes @ 2024
                  </div>{" "}
                  <div className="w-full items-center justify-center text-center flex">
                    <a href="https://dragoneyes.xyz" target="blank">
                      <img src={loka_icon} className="w-[30px]  "></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Header className="z-999999" />
          <section>
            <div className="w-full flex flex-col items-center ">
              {/* Hero */}
              <div className="flex flex-col w-full  relative items-center px-5 lg:px-5 gap-10 lg:gap-0">
                {/* Money bag */}
                <div className="w-full lg:absolute  relative z-10">
                  <div className="relative flex items-center justify-center ">
                    <img
                      src={moneyBag}
                      className="h-screen w-screen  scale-125 lg:scale-100 hidden lg:flex"
                    />
                    <img
                      src={moneyBag2}
                      className="w-full h-full object-cover scale-100 lg:scale-100  lg:hidden"
                    />
                  </div>
                  {/* Roll em now */}
                  <div className="z-50  lg:hidden w-full py-4 px-8  relative items-center  ">
                    {connected ? (
                      parseInt(userData.availableDiceRoll) < 1 ? (
                        <button
                          onClick={() => {
                            setBuyOpen(true);
                            //setModalOpen(true);
                            //launchConfetti();
                          }}
                          className="w-[350px] right-0 text-2xl  lg:hidden lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]"
                        >
                          Buy Ticket
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setModalOpen(true);
                          }}
                          className="w-[350px] right-0 text-2xl lg:hidden lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-animate-color"
                        >
                          Roll em now!
                        </button>
                      )
                    ) : (
                      <button
                        onClick={() => {
                          setConnectOpen(true);
                        }}
                        className="w-[350px] text-2xl lg:hidden lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557] "
                      >
                        Connect Wallet to Play
                      </button>
                    )}
                  </div>
                </div>

                <div className="z-40 relative bg-dark-cream bg-opacity-65 py-2 lg:ml-auto top-5 lg:top-0 mt-32   rounded-lg w-full lg:w-[350px]  px-8 flex flex-col gap-3">
                  {/* Dice */}
                  <div className="absolute w-64 -top-20 right-0 z-0">
                    <img src={dice} />
                  </div>

                  <div className="h-14"></div>
                  {/* Hook text */}
                  <div className=" lg:text-right">
                    <p className="text-3xl  lg:text-[40px] font-passero leading-tight">
                      Win{" "}
                      <span className="text-bright-red">
                        {gameData
                          ? Number(gameData.ok.reward) / Number(ticketPrice) +
                            "x"
                          : "10x ++"}
                      </span>
                      <br />
                      <span className="text-2xl">
                        {ticketPrice
                          ? "With only " +
                            Number(ticketPrice) / 10 ** 18 +
                            " ETH!"
                          : ""}
                      </span>
                      <br /> Roll the dice now!
                    </p>
                  </div>
                  <p className="text-[28px] text-dark-blue font-passero lg:text-right leading-tight">
                    You win when you got dragon eyes (1-1).
                  </p>
                  {/* Tooltip (On-chain fairplay) 
            <div className="lg:absolute right-0 -bottom-[86px] z-10">
              <div className="relative bg-bold-blue p-4 text-right leading-none rounded-md text-white font-semibold flex items-center">
                <p>
                  On-chain fairplay guaranteed.
                  <br />
                  Check contract{" "}
                  <span className="inline-block text-warning-yellow">here</span>
                </p>
                <div className="bg-bold-blue h-4 aspect-square hidden lg:flex absolute right-8 -top-2 rotate-45"></div>
              </div>
            </div>*/}
                </div>
                {/* Roll em now */}
                <div className="z-50 w-full  py-2 lg:ml-auto top-10 lg:top-0 mt-5 lg:mt-5 rounded-lg right-0 items-end  max-w-md px-0 flex flex-col gap-3 ">
                  {ethconnected ? (
                    parseInt(userData.availableDiceRoll) > 0 ? (
                      <>
                        <button
                          onClick={() => {
                            setModalOpen(true);
                          }}
                          className="w-[350px] right-0 text-2xl hidden lg:flex lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white bg-animate-color rounded-lg "
                        >
                          Roll em now!
                        </button>
                        {userData ? (
                          <p className="hidden lg:flex text-lg text-white">
                            you have {userData.availableDiceRoll.toString()}{" "}
                            dice roll ticket
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            setBuyOpen(true);
                            //setModalOpen(true);
                            //launchConfetti();
                          }}
                          className="w-[350px] right-0 text-2xl hidden lg:flex lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]"
                        >
                          Buy Ticket
                        </button>
                        {userData ? (
                          <p className="hidden lg:flex text-lg text-white">
                            you have {userData.availableDiceRoll.toString()}{" "}
                            dice roll ticket
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  ) : (
                    <CustomConnect />
                  )}
                </div>
              </div>
            </div>
          </section>
          {/*<section id="howtoplay w-full flex justify-center text-center  ">
        <div className="w-full bg-[#1E3557] flex flex-col items-center lg:px-5 pt-5 lg:pt-5 pb-12">
          <div className="flex flex-col w-full max-w-7xl justify-center text-center items-center px-5 lg:px-0 gap-10 lg:gap-0">
            <div className="grid w-full  justify-center text-center items-center">
              <div className="grid w-full pt-7 items-center justify-center">
                <p className="w-full lg:text-center text-left  text-5xl leading-none font-passion text-warm-white ">
                  Game Rules
                </p>
                <div className="w-full flex lg:text-center text-left items-center justify-center text-xl py-10 text-warm-white ">
                  <p className="lg:max-w-[50%] lg:text-center text-left">
                    There are 2 treasure chests guarded by a Dragon and a Dwarf
                    for 2 winners.
                    <br />
                    <br />
                    <span className="text-2xl">
                      Win the treasure by rolling the dice.
                    </span>
                    <br />
                    <br />
                    <span className="text-left">
                      - If you get snake eyes (both dice shows “1”), you win the
                      Dragon’s chest.
                      <br />
                      - If you get any doubles except snake eyes and double six
                      (2-2, 3-3, 4-4, 5-5), you get an extra dice roll.
                      <br />
                      - If you get the earliest and any highest* number among
                      all the dice rollers, you will win the Dwarf’s chest. The
                      first double six (6-6) would be a guaranteed winner.
                      <br />
                      <br />
                      The treasure value in both chests will keep increasing for
                      every roll until someone win the Dragon’s chest by getting
                      snake eyes, then a new round will start.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="howtoplay w-full flex justify-center text-center ">
        <div className="w-full flex flex-col items-center lg:px-5 pt-5 lg:pt-5 pb-12">
          <div className="flex flex-col w-full max-w-7xl justify-center text-center items-center px-5 lg:px-0 gap-10 lg:gap-0">
            <div className="grid w-full  justify-center text-center items-center">
              <div className="grid w-full">
                <p className="w-full text-left pt-6  text-5xl leading-none font-passion text-[#1E3557]">
                  How To Play
                </p>
                <p className="w-full text-left text-xl lg:pr-[40%]">
                  1. Connect wallet and get a new wallet address auto generated
                  for you.
                  <br />
                  2. Transfer some ICP to your new wallet address.
                  <br />
                  3. Buy ticket and roll the dice!
                  <br />
                  <br />
                  <br />
                  Use Claim if you win to redeem your prize.
                  <br />
                  <br />
                  Use Withdraw if you want to transfer out your ICP to your main
                  wallet or exchange.
                </p>
              </div>
              <div className="grid w-full pt-7">
                <p className="w-full lg:text-right text-left  text-5xl leading-none font-passion text-[#1E3557]">
                  How can I be sure I’m not being cheated?
                </p>
                <p className="w-full text-left text-xl lg:pl-[40%]">
                  This game is a testament to how provably secure randomness can
                  be achieved fully on-chain on ICP — a follow-up to{" "}
                  <a
                    href="https://x.com/JanCamenisch/status/1742086862062112827?s=20"
                    target="blank"
                  >
                    <b>Jan Camenisch's tweet here</b>
                  </a>
                  . <br />
                  
                  <a
                    href="https://dashboard.internetcomputer.org/canister/s4bfy-iaaaa-aaaam-ab4qa-cai"
                    target="blank"
                  >
                    {" "}
                    <b>IC Dragon candid interface can be checked here</b>
                    <br />
                    canister id : s4bfy-iaaaa-aaaam-ab4qa-cai
                  </a>
                  <br />
                  You can also{" "}
                  <a
                    href="https://github.com/lokaverse/icdragon/blob/b47953ca05a9a43399fc2606cf0cbb3d0977f21d/src/icdragon/main.mo#L516"
                    target="blank"
                  >
                    <b>check the code yourself here.</b>
                  </a>
                </p>
              </div>
              <div className="grid w-full pt-7">
                <p className="w-full text-left  text-5xl leading-none font-passion text-[#1E3557]">
                  What is $EYES?
                </p>
                <p className="w-full text-left text-xl lg:pr-[40%]">
                  It’s a reward token you get each time you roll the dices.The
                  higher sum number of dices comes up, the more token you will
                  receive.
                  <br />
                  $EYES emission has halving every 10 days, meaning that the
                  same roll result will get ½ distribution after each halving.
                  <br />
                  You can find{" "}
                  <a
                    href="https://dashboard.internetcomputer.org/canister/xa275-diaaa-aaaam-ab4pq-cai"
                    target="blank"
                  >
                    <b>$EYES token ICP candid interface here</b>
                  </a>
                </p>
              </div>
              <div className="grid w-full pt-7">
                <p className="w-full lg:text-right text-left  text-5xl leading-none font-passion text-[#1E3557]">
                  What is the utility of $EYES?
                </p>
                <p className="w-full text-left text-xl lg:pl-[40%]">
                  Nothing for now. It might be worth something in the future. We
                  won't promise anything though.
                </p>
              </div>
            </div>
          </div>
        </div>
                </section> */}
          <ConnectModal className="z-70" />
          <GameModal className="z-70" />
          <BuyTicketModal className="z-70" />
          <ClaimModal className="z-70" />
          <TransferModal className="z-70" />
          <HistoryModal className="z-70" />
          <GuideModal className="z-70" />
        </div>
      ) : (
        <div
          className="h-screen  bg-cover bg-center"
          style={{ backgroundImage: `url(${dragonMobile})` }}
        >
          <Header className="z-999999" />
          <div className="w-full absolute bottom-[0%] lg:hidden items-center justify-center text-center px-4   z-10">
            <div className="z-40 w-full bg-dark-cream bg-opacity-85 py-2  top-2 lg:top-0  lg:mt-32 rounded-lg px-8 flex flex-col gap-3">
              {/* Dice */}

              <div className="absolute w-64 -top-20 left-0 z-0">
                <img src={dice} className="w-[200px]" />
              </div>

              {/* Hook text */}
              <div className=" lg:text-right">
                <div className="text-xl py-0 w-full grid  lg:text-[40px] font-passero leading-tight">
                  <div className="text-[#EE5151] grid w-full">
                    <div className="text-dark-blue">Dragon's Chest</div>
                    <div className="font-passion text-3xl">
                      {gameData ? Number(gameData.ok.reward) / 10 ** 18 : ""}{" "}
                      ETH
                    </div>
                  </div>

                  <div className="text-base flex w-full text-dark-blue font-passero justify-center text-center items-center leading-tight">
                    Win{" "}
                    <div className="text-[#EE5151] text-lg">
                      {gameData
                        ? Number(gameData.ok.reward) / Number(ticketPrice)
                        : ""}
                      x
                    </div>{" "}
                    {ticketPrice
                      ? "with only " + Number(ticketPrice) / 10 ** 18 + " ETH!"
                      : ""}
                  </div>
                </div>
              </div>
              <div className="grid lg:hidden text-xl font-passion text-center bg-opacity-70 items-center justify-center  z-40 text-red-500 w-200 bg-warm-white rounded-2xl p-1 px-6">
                <div className="flex p-0 items-center justify-center text-center">
                  {halving
                    ? Object.entries(timeLeft).map(([unit, value]) => (
                        <div key={unit} className="px-1 grid">
                          <div className="text-base font-bold">
                            {value < 10 ? `0${value}` : value}
                          </div>
                          <div className="text-sm uppercase">{unit}</div>
                        </div>
                      ))
                    : "Starting Countdown..."}
                </div>
                <div className="flex text-sm w-full justify-center items-center text-center text-dark-blue">
                  until next $EYES halving
                </div>
              </div>
            </div>

            <div className="z-40 w-full bg-dark-blue bg-opacity-85 py-1  top-2 lg:top-0  mt-5 rounded-lg px-8 flex flex-col gap-2">
              <div className="flex">
                <div className=" p-2  font-passero leading-tight">
                  <div className="text-[#EE5151] text-base w-[120px]">
                    Dwarf's Bonus
                  </div>
                  <div className="font-passion text-xl text-warm-white">
                    {gameData ? Number(gameData.ok.bonus) / 10 ** 18 : ""} ETH
                  </div>
                  <br />
                </div>
                <div>
                  <div className="text-base flex text-black font-passero w-full items-start justify-center ">
                    <div className="p-2   text-[#EE5151] grid text-center items-center justify-center">
                      {"Current Highest "}

                      <span className="text-2xl text-warm-white text-center items-center justify-center ">
                        {gameData
                          ? Number(gameData.ok.highestDice).toString()
                          : ""}
                      </span>
                    </div>
                    <div className="p-2 text-[#EE5151] text-base">
                      {"Roller Address "}
                      <span className="text-base text-warm-white ">
                        {gameData
                          ? truncateString(
                              gameData.ok.highestRoller.toString(),
                              14
                            )
                          : ""}
                      </span>
                      <br />{" "}
                      <span className="text-lg ">
                        {gameData && walletAddress
                          ? walletAlias == gameData.ok.highestRoller.toString()
                            ? "(You!)"
                            : ""
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Roll em now */}
            <div className="z-50  lg:hidden w-full py-4 px-8  justify-center items-center  ">
              {ethconnected ? (
                parseInt(userData.availableDiceRoll) < 1 ? (
                  <button
                    onClick={() => {
                      setBuyOpen(true);
                      //launchConfetti();
                    }}
                    className="w-full text-2xl  lg:hidden lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#EE5151]"
                  >
                    Buy Ticket
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setModalOpen(true);
                    }}
                    className="w-full  text-2xl lg:hidden lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-animate-color"
                  >
                    Roll em now!
                  </button>
                )
              ) : (
                <CustomConnectSmall />
              )}
            </div>
          </div>
          <section>
            <div className="w-full h-full flex flex-col items-center">
              {/* Hero */}
              <div className="flex flex-col w-full  relative items-center px-5 lg:px-5 gap-10 lg:gap-0">
                {/* Money bag */}

                {/* Roll em now */}
                <div className="z-50 w-full  py-2 lg:ml-auto top-10 lg:top-0 mt-5 lg:mt-5 rounded-lg  items-end  max-w-md px-0 flex flex-col gap-3 ">
                  {connected ? (
                    parseInt(userData.availableDiceRoll) > 0 ? (
                      <>
                        <button
                          onClick={() => {
                            setModalOpen(true);
                          }}
                          className="w-[350px]  text-2xl hidden lg:flex lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white bg-animate-color rounded-lg "
                        >
                          Roll em now!
                        </button>
                        {userData ? (
                          <p className="hidden lg:flex text-lg text-white">
                            you have {userData.availableDiceRoll.toString()}{" "}
                            dice roll ticket
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            setBuyOpen(true);
                            //launchConfetti();
                          }}
                          className="w-[350px]  text-2xl hidden lg:flex lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]"
                        >
                          Buy Ticket
                        </button>
                        {userData ? (
                          <p className="hidden lg:flex text-lg text-white">
                            you have {userData.availableDiceRoll.toString()}{" "}
                            dice roll ticket
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        setConnectOpen(true);
                      }}
                      className="w-[350px] text-2xl hidden lg:flex lg:text-[54px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557] "
                    >
                      Connect Wallet to Play
                    </button>
                  )}
                </div>
              </div>
            </div>
          </section>
          {/* */}
          <ConnectModal className="z-70" />
          <GameModal className="z-70" />
          <BuyTicketModal className="z-70" />
          <ClaimModal className="z-70" />
          <TransferModal className="z-70" />
          <HistoryModal className="z-70" />
          <GuideModal className="z-70" />
        </div>
      )}
    </div>
  );
};

export default Landing;
